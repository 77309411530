
import FooterLinkSection from '@/components/layouts/footer/FooterLinkSection.vue';
import { useRoute } from 'vue-router';

export default {
    name : "FooterView",
    components: { 
        FooterLinkSection 
    },    
    setup() {
        const route = useRoute()
        return {
            route
        }
    }

}
