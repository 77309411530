
import { defineComponent } from 'vue';
import PsLabel from './PsLabel.vue';

export default defineComponent({
    name : 'PsLabelCaption',
    components: { 
        PsLabel 
    },
    props: {
        textColor : {
            type: String,               
            default: 'text-secondary-400 dark:text-secondaryDark-grey',                
        }
    }    
});
