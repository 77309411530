export default class PsConfig {

    static versionNo : String = '1.0.16';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "teampsisthebest";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///
    static fixedDomain: String = "https://dizapego.com/app_diz"; 
    static domain: String = "https://dizapego.com/app_diz"; 

    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///
    // static fixedDomain: String = "https://products.panacea-soft.co/flutter-buysell-demo";    
    // static domain: String = "http://localhost:3000";

    ///
    /// Firebase Config
    ///
    static firebaseConfig = {
        apiKey: "AIzaSyAezrfhdTsV-bLtfuAmoH4KxoojIRdf5VE",
        authDomain: "dizapegopro-a787c.firebaseapp.com",
        databaseURL: "https://dizapegopro-a787c-default-rtdb.firebaseio.com",
        projectId: "dizapegopro-a787c",
        storageBucket: "dizapegopro-a787c.appspot.com",
        messagingSenderId: "601632494240",
        appId: "1:601632494240:web:c51938736d8e070f7f6fc5",
        measurementId: "G-KK8X8P5VP6"            
    };
    
    // static firebaseConfig = {
    //     apiKey: "AIzaSyCtBHbqTWRgh9O8veCOJNnCMG56lXTdGJw",
    //     authDomain: "flutter-buy-and-sell.firebaseapp.com",
    //     databaseURL: "https://flutter-buy-and-sell.firebaseio.com",
    //     projectId: "flutter-buy-and-sell",
    //     storageBucket: "flutter-buy-and-sell.appspot.com",
    //     messagingSenderId: "505384053995",
    //     appId: "1:505384053995:web:f22081b8b835af474ae2ae",
    //     measurementId: "G-2CNXCDNZ2Y"
    // };
    
    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain; 
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;
 
    /// 
    /// Debug Mode
    ///
    static isDebugMode : Boolean = true;

    /// Is Demo
    ///
    static is_demo : Boolean = true;

    static item_upload_ui1 : Boolean = false;

    static item_upload_ui2 : Boolean = true;
}
